import { TCallbackFunction } from "../../interfaces/common"
import { useAppActions } from "../../stores/application/useAppActions"
import { useAppStore } from "../../stores/application/useAppStore"

export interface IChromeRuntimeMessageParams {
    enabled?: boolean,
    slug: string
    url: string
    action: 'action' | 'linkedinAction'
    params?: Object
    payload?: Object,
    errorClb?: TCallbackFunction<any>
    displayToast?: boolean;
    all?: boolean;
}

const sendChromeMessage = (data: any, extensionId: string) => new Promise((resolve, reject) => {
    try {
        const runtime = (window as any).chrome.runtime;

        if (!runtime?.sendMessage) return reject(new Error("There was a problem performing this action!"));
        console.log(extensionId)//todo: read from store if sent differently

        runtime.sendMessage(extensionId, data, (response: any) => {
            if (runtime.lastError) {
              console.error('Error sending message:', runtime.lastError);
              return reject(new Error("There was a problem performing this action!"))
            }
            console.log('Response from extension:', response);

            if (response.error && typeof response.error === 'string') { //todo: check for those that return result data to be checked or something 
                return reject(new Error(response.error)); 
            } 
            if (response.result) return resolve(response); 

              
              resolve(response);
          });

    }
    catch (error) {
        console.error (error);

        return reject(new Error("There was a problem performing this action!"));
    }
});

export const useChromeRuntime = () => {
    const { setRequestLoading, setToast } = useAppActions()
    const { store: { extensionId } } = useAppStore()

    const sendMessage = async ({ slug, url, action, payload, all = false, displayToast = false, enabled = true}: IChromeRuntimeMessageParams) => {   
        try {
            setRequestLoading({ [slug]: true });

            if (!enabled) throw Error("Forbidden!");

            const data: any = await sendChromeMessage({
                [action as string]: url,
                ...payload
            }, extensionId)
            console.log("Valid response", data)

            if (displayToast) setToast({ type: 'success', message: "Action succeeded!" });

            return all ? data : data?.result;
        }
        catch (error) {
            //console.log("Valid error", error);
            if (displayToast) setToast({ type: 'error', message: "There was a problem performing this action!" });
            return all ? error : false;
        }
        finally {
            setRequestLoading({ [slug]: false })
        }
    };
    return { sendMessage }
}