import {
  useState,
  useMemo,
  FunctionComponent,
  useEffect,
  useCallback,
} from "react";
import { Column, Connection, Id } from "./types";
import ColumnContainer from "./ColumnContainer";
import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  useSensors,
  useSensor,
  PointerSensor,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import ConnectionCard from "./ConnectionCard";
import styled from "styled-components";
import { IConnection } from "../../../../interfaces/models/connection";
import { styles } from "../../../../styles/themes/style";
import { useConnectionsHandlers } from "../../../../services/actions/connections/useConnectionsHandlers";
import { useAuthStore } from "../../../../stores/auth/useAuthStore";
import Text from "../../../../components/Text";
import { IKanban } from "../../../../interfaces/models/kanban";
import { SidebarTypes } from "../../../../interfaces/store/appStore";
import { useAppHandlers } from "../../../../services/actions/app/useAppHandlers";
import TableRow from "../../../../components/table/TableRow";
import TableCell from "../../../../components/table/TableCell";
import LoaderCell from "../../../../components/LoaderCell";
import SvgFragment from "../../../../fragments/SvgFragment";
import KanbanInfoPopup from "../../../../components/KanbanInfoPopup";

interface IKanbanBoardProps {
  selectedConnections: IConnection[];
}

const KanbanBoard: FunctionComponent<IKanbanBoardProps> = ({
  selectedConnections,
}) => {
  const [columns, setColumns] = useState<Column[]>([]);
  const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [kanban, setKanban] = useState<IKanban | undefined>(undefined);
  const [activeConnection, setActiveConnection] = useState<Connection | null>(
    null
  );
  const [kanbanLoading, setKanbanLoading] = useState<boolean>(true);
  const [connections, setConnections] = useState<Connection[]>(
    selectedConnections.map((connection) => ({
      id: generateId(),
      columnId: columns && columns.length && columns[0].id,
      content: connection.firstName + " " + connection.lastName,
      connection: connection,
    }))
  );

  const [editModeName, setEditModeName] = useState(false);
  const [editModeDesc, setEditModeDesc] = useState(false);

  const {
    store: { workspace },
  } = useAuthStore();
  const { openSidebar, setDestructiveModal, hideModal } = useAppHandlers();
  const {
    getKanbanWithConnectionsHandler,
    updateConnectionColumnIdInKanbanHandler,
    addColumnToKanbanHandler,
    updateKanbanColumnHandler,
    addConnectionToKanbanHandler,
    updateKanbanHandler,
    removeConnectionFromKanbanHandler,
    reorderKanbanColumnsHandler,
    removeKanbanHandler,
    removeKanbanColumnHandler,
  } = useConnectionsHandlers();

  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 3 },
    })
  );

  const [dragOperation, setDragOperation] = useState<{
    activeId: Id | null;
    overId: Id | null;
    activeIndex: number | null;
    overIndex: number | null;
    newColumnId: Id | null;
  }>({
    activeId: null,
    overId: null,
    activeIndex: null,
    overIndex: null,
    newColumnId: null,
  });

  useEffect(() => {
    getKanbanWithConnections();
  }, []);

  const getKanbanWithConnections = useCallback(async () => {
    const result = await getKanbanWithConnectionsHandler(workspace?._id);
    if (result?.kanban) {
      setColumns(result?.kanban?.columns);
      setKanban(result?.kanban);
      setKanbanLoading(false);
    }
    if (result?.connections) {
      setConnections(
        result?.connections.map((connection: IConnection) => {
          const firstName = findPValue("firstName", connection);
          const lastName = findPValue("lastName", connection);
          const profilePicture = findPValue("profilePicture", connection);
          const headline = findPValue("headline", connection);
          const columnId = connection?.kanbanColumns?.find(
            (c) => c.kanbanId === result?.kanban?._id
          )?.columnId;

          return {
            id: generateId(),
            columnId: columnId,
            content: `${firstName} ${lastName}`.trim(),
            connection: {
              ...connection,
              firstName,
              lastName,
              profilePicture,
              headline,
              tags: connection.tagsArray,
            },
          };
        })
      );
    } else {
      setKanbanLoading(false);
    }
  }, []);

  const findPValue = (key: string, connection: any) => {
    const item = connection.p?.find((entry: { k: string }) => entry.k === key);
    return item?.v || "";
  };

  async function createNewColumn() {
    let baseTitle = "Column";
    let maxNumber = 0;

    // find max column id in titles
    columns.forEach((col) => {
      const match = col.title.match(/^Column (\d+)$/);
      if (match) {
        const num = parseInt(match[1], 10);
        if (num > maxNumber) {
          maxNumber = num;
        }
      }
    });

    let columnToAdd: Column = {
      id: generateId(),
      title: `${baseTitle} ${maxNumber + 1}`,
    };

    setColumns([...columns, columnToAdd]);

    const result = await addColumnToKanbanHandler({
      kanbanId: kanban?._id,
      column: columnToAdd,
    });

    if (!kanban && result.kanban) {
      setKanban(result.kanban);
    }
  }

  function generateId() {
    return (Math.floor(Math.random() * 1000) + 1).toString();
  }

  async function updateColumn(id: Id, title: string) {
    const editingColumn = columns.find((col) => col.id === id);
    const newColumns = columns.map((col) => {
      if (col.id !== id) return col;
      return { ...col, title };
    });
    setColumns(newColumns);
    const result = await updateKanbanColumnHandler(kanban?._id, {
      columnId: id,
      columnTitle: title,
    });
    if (result?.updatedTagId) {
      setConnections((prevConnections: any) => {
        return prevConnections.map((conn: any) => {
          const updatedTagsArray = conn?.connection?.tagsArray?.map(
            (tag: any) => {
              if (tag._id === result.updatedTagId) {
                return { ...tag, title };
              }
              return tag;
            }
          );

          if (updatedTagsArray) {
            return {
              ...conn,
              connection: {
                ...conn.connection,
                tagsArray: updatedTagsArray,
                tags: updatedTagsArray,
              },
            };
          }
          return conn;
        });
      });
    } else if (result?.reason === "tagExists") {
      const newColumns = columns.map((col) => {
        if (col.id !== id) return col;
        return { ...col, title: editingColumn?.title ?? "" };
      });
      setColumns(newColumns);
    }
  }

  function updateKanban(id: Id, value: string, isName: boolean) {
    const updatedKanban = {
      ...kanban,
      [isName ? "name" : "description"]: value,
    } as IKanban;
    setKanban(updatedKanban);
    updateKanbanHandler(kanban?._id, {
      [isName ? "name" : "description"]: value,
    });
  }

  const onRemoveKanbanHandler = async () => {
    setDestructiveModal({
      headingText: `Are you sure you want to delete ${kanban?.name} Kanban?`,
      descriptionText: (
        <span>
          Deleting the <b>{kanban?.name}</b> Kanban will also remove all
          connections and the associated tags. This action cannot be undone.
        </span>
      ),
      onCancelHandler: () => hideModal(),
      onConfirmHandler: async () => {
        hideModal();
        removeKanban();
      },
    });
  };

  function removeKanban() {
    setKanban(undefined);
    setColumns([]);
    setConnections([]);
    removeKanbanHandler(kanban?._id);
  }

  async function addConnectionToColumn(columnId: Id, connection: any) {
    let newConnection: Connection = {
      id: generateId(),
      columnId,
      content: `Connection ${connections.length + 1}`,
      connection: connection,
    };
    setConnections([...connections, newConnection]);
    try {
      const result = await addConnectionToKanbanHandler(
        {
          connectionId: connection?._id,
          columnId,
        },
        false
      );
      if (result?.connection) {
        const updatedConnection = {
          ...result?.connection,
          tags: result?.connection.tagsArray,
          firstName: findPValue("firstName", result?.connection),
          lastName: findPValue("lastName", result?.connection),
          profilePicture: findPValue("profilePicture", result?.connection),
          headline: findPValue("headline", result?.connection),
        };
        newConnection = { ...newConnection, connection: updatedConnection };
        setConnections([...connections, newConnection]);
      } else {
        setConnections(connections.filter((c) => c.id !== newConnection.id));
        return;
      }
    } catch (error: any) {
      console.error("Error adding connection to Kanban:");
      console.error(error);
    }
  }

  async function removeConnection(id: Id) {
    const connectionForRemove = connections.find((c) => c.id === id);
    try {
      setConnections(connections.filter((c) => c.id !== id));

      const result = await removeConnectionFromKanbanHandler(
        connectionForRemove?.connection?._id,
        {
          connectionId: connectionForRemove?.connection?._id,
          kanbanId: kanban?._id,
          columnId: connectionForRemove?.columnId,
        }
      );

      if (!result?.connection) {
        if (connectionForRemove) {
          setConnections([...connections, connectionForRemove]);
        }
        return;
      }
    } catch (error) {
      console.error("Error removing connection from Kanban:", error);
    }
  }

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column);
      return;
    }
    if (event.active.data.current?.type === "Connection") {
      setActiveConnection(event.active.data.current.connection);
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    setActiveColumn(null);
    setActiveConnection(null);

    if (!over) return;

    // Handle column reordering
    if (active.data.current?.type === "Column") {
      console.log(active.id, over.id);

      setColumns((columns) => {
        const activeColumnIndex = columns.findIndex(
          (col) => col.id === active.id
        );
        const overColumnIndex = columns.findIndex((col) => col.id === over.id);
        return arrayMove(columns, activeColumnIndex, overColumnIndex);
      });

      // call API
      handleColumnsReorder(active.id, over.id);
      return;
    }

    // Handle connection movement between columns
    if (dragOperation.newColumnId) {
      handleConnectionMoveToNewColumn();
    }

    // Reset drag operation
    setDragOperation({
      activeId: null,
      overId: null,
      activeIndex: null,
      overIndex: null,
      newColumnId: null,
    });
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveAConnection = active.data.current?.type === "Connection";
    const isOverAConnection = over.data.current?.type === "Connection";
    const isOverAColumn = over.data.current?.type === "Column";

    if (!isActiveAConnection) return;

    let activeIndex = connections.findIndex((conn) => conn.id === activeId);
    let newColumnId: Id | null = null;

    if (isActiveAConnection && isOverAConnection) {
      const overIndex = connections.findIndex((conn) => conn.id === overId);

      if (
        connections[activeIndex].columnId !== connections[overIndex].columnId
      ) {
        newColumnId = connections[overIndex].columnId;
      } else {
        if (
          connections[activeIndex].columnId === connections[overIndex].columnId
        ) {
          // the same column, only reordering
          setConnections((connections) => {
            connections[activeIndex].columnId = connections[overIndex].columnId;
            return arrayMove(connections, activeIndex, overIndex);
          });

          activeIndex = connections.findIndex((conn) => conn.id === activeId);
        } else {
          const newColumnId = connections[overIndex].columnId;
          const kanbanId = kanban?._id;

          const updatedKanbanColumns = updateKanbanColumns(
            activeConnection?.connection?.kanbanColumns || [],
            kanbanId,
            newColumnId
          );

          const updatedConnection = {
            ...connections[activeIndex].connection,
            kanbanColumns: updatedKanbanColumns,
          };

          setConnections((connections) => {
            connections[activeIndex].columnId = newColumnId;
            connections[activeIndex].connection =
              updatedConnection as IConnection;
            return arrayMove(connections, activeIndex, overIndex);
          });

          activeIndex = connections.findIndex((conn) => conn.id === activeId);
        }
      }
    } else if (isActiveAConnection && isOverAColumn) {
      newColumnId = overId;
    }

    if (newColumnId) {
      // Update UI immediately for smooth visual feedback
      const updatedKanbanColumns = updateKanbanColumns(
        activeConnection?.connection?.kanbanColumns || [],
        kanban?._id,
        newColumnId
      );

      const updatedConnection = {
        ...connections[activeIndex].connection,
        kanbanColumns: updatedKanbanColumns,
      };

      setConnections((connections) => {
        connections[activeIndex].columnId = newColumnId!;
        connections[activeIndex].connection = updatedConnection as IConnection;
        return arrayMove(connections, activeIndex, activeIndex);
      });

      // Store the drag operation for completion in onDragEnd
      setDragOperation({
        activeId,
        overId,
        activeIndex,
        overIndex: isOverAConnection
          ? connections.findIndex((conn) => conn.id === overId)
          : null,
        newColumnId,
      });
    }
  }

  async function handleColumnsReorder(
    activeColumnId?: any,
    overColumnId?: any
  ) {
    try {
      if (!activeColumnId || !overColumnId) return;
      const payload = { activeColumnId, overColumnId };
      await reorderKanbanColumnsHandler(kanban?._id, payload);
    } catch (error) {
      console.error("Failed to reorder columns:", error);
      // Revert optimistic update on error
      setColumns((columns) => {
        const activeColumnIndex = columns.findIndex(
          (col) => col.id === activeColumnId
        );
        const overColumnIndex = columns.findIndex(
          (col) => col.id === overColumnId
        );
        return arrayMove(columns, overColumnIndex, activeColumnIndex);
      });
    }
  }

  async function handleConnectionMoveToNewColumn() {
    if (
      !dragOperation.activeId ||
      !dragOperation.newColumnId ||
      dragOperation.activeIndex === null
    )
      return;

    const { newColumnId, activeIndex } = dragOperation;
    const kanbanId = kanban?._id;

    // Optimistically update UI
    setConnections((prev) => {
      const newConnections = [...prev];
      newConnections[activeIndex].columnId = newColumnId;
      return newConnections;
    });

    try {
      const payload = { kanbanId, columnId: newColumnId };
      const result = await updateConnectionColumnIdInKanbanHandler(
        connections[activeIndex].connection?._id,
        payload
      );

      if (result?.connection) {
        const updatedConnection = {
          ...result.connection,
          tags: result.connection.tagsArray,
          firstName: findPValue("firstName", result.connection),
          lastName: findPValue("lastName", result.connection),
          profilePicture: findPValue("profilePicture", result.connection),
          headline: findPValue("headline", result.connection),
        };

        setConnections((connections) => {
          connections[activeIndex].connection =
            updatedConnection as IConnection;
          return connections;
        });
      }
    } catch (error) {
      console.error("Failed to update connection:", error);
      // Revert optimistic update on error
      setConnections((prev) => {
        const newConnections = [...prev];
        newConnections[activeIndex].columnId =
          connections[activeIndex].columnId;
        return newConnections;
      });
    }
  }

  // function onDragEnd(event: DragEndEvent) {
  //   setActiveColumn(null);
  //   setActiveConnection(null);

  //   const { active, over } = event;
  //   if (!over) return;

  //   const activeColumnId = active.id;
  //   const overColumnId = over.id;
  //   if (activeColumnId === overColumnId) return;

  //   const isActiveAConnection = active.data.current?.type === "Connection";
  //   const isActiveAColumn = active.data.current?.type === "Column";

  //   if (isActiveAColumn) {
  //     console.log("KOLONA D&D");

  //     setColumns((columns) => {
  //       const activeColumnIndex = columns.findIndex(
  //         (col) => col.id === activeColumnId
  //       );
  //       const overColumnIndex = columns.findIndex(
  //         (col) => col.id === overColumnId
  //       );
  //       return arrayMove(columns, activeColumnIndex, overColumnIndex);
  //     });
  //   }
  // }

  // async function onDragOver(event: DragOverEvent) {
  //   const { active, over } = event;
  //   if (!over) return;

  //   const activeId = active.id;
  //   const overId = over.id;

  //   if (activeId === overId) return;

  //   const isActiveAConnection = active.data.current?.type === "Connection";
  //   const isOverAConnection = over.data.current?.type === "Connection";

  //   if (!isActiveAConnection) return;

  //   if (isActiveAConnection && isOverAConnection) {
  //     const activeIndex = connections.findIndex((conn) => conn.id === activeId);
  //     const overIndex = connections.findIndex((conn) => conn.id === overId);

  //     if (
  //       connections[activeIndex].columnId === connections[overIndex].columnId
  //     ) {
  //       // the same column, only reordering
  //       setConnections((connections) => {
  //         connections[activeIndex].columnId = connections[overIndex].columnId;
  //         return arrayMove(connections, activeIndex, overIndex);
  //       });
  //     } else {
  //       const newColumnId = connections[overIndex].columnId;
  //       const kanbanId = kanban?._id;

  //       const updatedKanbanColumns = updateKanbanColumns(
  //         activeConnection?.connection?.kanbanColumns || [],
  //         kanbanId,
  //         newColumnId
  //       );

  //       const updatedConnection = {
  //         ...connections[activeIndex].connection,
  //         kanbanColumns: updatedKanbanColumns,
  //       };

  //       setConnections((connections) => {
  //         connections[activeIndex].columnId = newColumnId;
  //         connections[activeIndex].connection =
  //           updatedConnection as IConnection;
  //         return arrayMove(connections, activeIndex, overIndex);
  //       });

  //       const payload = { kanbanId, columnId: newColumnId };
  //       const result = await updateConnectionColumnIdInKanbanHandler(
  //         activeConnection?.connection?._id,
  //         payload
  //       );

  //       if (result?.connection) {
  //         const updatedConnection = {
  //           ...result?.connection,
  //           tags: result?.connection.tagsArray,
  //           firstName: findPValue("firstName", result?.connection),
  //           lastName: findPValue("lastName", result?.connection),
  //           profilePicture: findPValue("profilePicture", result?.connection),
  //           headline: findPValue("headline", result?.connection),
  //         };

  //         setConnections((connections) => {
  //           connections[activeIndex].connection =
  //             updatedConnection as IConnection;
  //           return connections;
  //         });
  //       }
  //     }
  //   }

  //   const isOverAColumn = over.data.current?.type === "Column";

  //   //dorpping a connection over another coloumn
  //   if (isActiveAConnection && isOverAColumn) {
  //     const activeIndex = connections.findIndex((conn) => conn.id === activeId);
  //     const newColumnId = overId;
  //     const kanbanId = kanban?._id;

  //     const updatedKanbanColumns = updateKanbanColumns(
  //       activeConnection?.connection?.kanbanColumns || [],
  //       kanbanId,
  //       newColumnId
  //     );

  //     const updatedConnection = {
  //       ...connections[activeIndex].connection,
  //       kanbanColumns: updatedKanbanColumns,
  //     };

  //     setConnections((connections) => {
  //       //const activeIndex = connections.findIndex((t) => t.id === activeId);
  //       connections[activeIndex].columnId = newColumnId;
  //       connections[activeIndex].connection = updatedConnection as IConnection;
  //       return arrayMove(connections, activeIndex, activeIndex);
  //     });

  //     const payload = { kanbanId, columnId: newColumnId };
  //     const result = await updateConnectionColumnIdInKanbanHandler(
  //       activeConnection?.connection?._id,
  //       payload
  //     );
  //     if (result?.connection) {
  //       const updatedConnection = {
  //         ...result?.connection,
  //         tags: result?.connection.tagsArray,
  //         firstName: findPValue("firstName", result?.connection),
  //         lastName: findPValue("lastName", result?.connection),
  //         profilePicture: findPValue("profilePicture", result?.connection),
  //         headline: findPValue("headline", result?.connection),
  //       };

  //       setConnections((connections) => {
  //         connections[activeIndex].connection =
  //           updatedConnection as IConnection;
  //         return connections;
  //       });
  //     }
  //   }
  // }

  function updateKanbanColumns(
    kanbanColumns: any[],
    kanbanId: any,
    columnId: any
  ) {
    const existingIndex = kanbanColumns.findIndex(
      (kc: any) => kc.kanbanId === kanbanId
    );

    if (existingIndex !== -1) {
      kanbanColumns[existingIndex].columnId = columnId;
    }
    // else {
    //   kanbanColumns.push({ kanbanId, columnId });
    // }

    return [...kanbanColumns];
  }

  const connectionClickedHandler = (connection: Connection) => {
    if (connection?.connection) {
      openSidebar(SidebarTypes.CONNECTION_ACTIONS, {
        connection: connection.connection,
      });
    }
  };

  const onRemoveColumnHandler = (column: Column) => {
    setDestructiveModal({
      headingText: `Are you sure you want to delete ${column?.title}?`,
      descriptionText: (
        <span>
          Deleting this column will also remove all connections it contains and
          the associated tag named <b>{column?.title}</b>. This action cannot be
          undone.
        </span>
      ),
      onCancelHandler: () => hideModal(),
      onConfirmHandler: async () => {
        hideModal();
        removeColumn(column);
      },
    });
  };

  function removeColumn(column: Column) {
    const filteredColumns = columns.filter((col) => col.id !== column?.id);
    setColumns(filteredColumns);
    const newConnections = connections.filter((t) => t.columnId !== column?.id);
    setConnections(newConnections);
    removeKanbanColumnHandler(kanban?._id, { columnId: column?.id });
  }

  const onChangeColumnColorHandler = (column: Column) => {
    console.log(column);
  };

  return (
    <Container>
      <HeaderWrapper>
        <KanbanInfoPopup
          kanban={kanban}
          updateKanban={updateKanban}
          removeKanban={onRemoveKanbanHandler}
        />
      </HeaderWrapper>
      {kanbanLoading ? (
        <KanbanPreloader />
      ) : (
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        >
          <ColumnsWrapper>
            <Columns>
              <SortableContext items={columnsId}>
                {columns.map((col) => (
                  <ColumnContainer
                    key={col.id}
                    column={col}
                    updateColumn={updateColumn}
                    addConnectionToColumn={addConnectionToColumn}
                    connections={connections.filter(
                      (connection) => connection.columnId === col.id
                    )}
                    removeConnection={removeConnection}
                    connectionClicked={connectionClickedHandler}
                    removeColumn={onRemoveColumnHandler}
                    changeColumnColor={onChangeColumnColorHandler}
                  />
                ))}
              </SortableContext>
            </Columns>
            <AddColumnButton onClick={createNewColumn}>
              <ColumnHeader>
                <SvgFragment type="plus" />
                <Text $label>Add Column</Text>
              </ColumnHeader>
            </AddColumnButton>
          </ColumnsWrapper>
          {createPortal(
            <DragOverlay>
              {activeColumn && (
                <ColumnContainer
                  column={activeColumn}
                  updateColumn={updateColumn}
                  addConnectionToColumn={addConnectionToColumn}
                  connections={connections.filter(
                    (connection) => connection.columnId === activeColumn.id
                  )}
                  removeConnection={removeConnection}
                  connectionClicked={connectionClickedHandler}
                  removeColumn={onRemoveColumnHandler}
                  changeColumnColor={onChangeColumnColorHandler}
                />
              )}
              {activeConnection && (
                <ConnectionCard
                  connection={activeConnection}
                  removeConnection={removeConnection}
                />
              )}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      )}
    </Container>
  );
};

const KanbanPreloader: FunctionComponent = () => {
  const rows = Array.from({ length: 15 });
  return (
    <KanbanPreloaderWrapper>
      {rows.map((_, index) => (
        <KanbanTableRow key={`preloader-row-${index}`}>
          <TableCell $width="20%">
            <LoaderCell $width="100%" $height="40px" />
          </TableCell>
          <TableCell $width="20%">
            <LoaderCell $width="100%" $height="40px" />
          </TableCell>
          <TableCell $width="20%">
            <LoaderCell $width="100%" $height="40px" />
          </TableCell>
          <TableCell $width="20%">
            <LoaderCell $width="100%" $height="40px" />
          </TableCell>
          <TableCell $width="20%">
            <LoaderCell $width="100%" $height="40px" />
          </TableCell>
        </KanbanTableRow>
      ))}
    </KanbanPreloaderWrapper>
  );
};

const KanbanPreloaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const ColumnHeader = styled.div`
  height: 32px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 4px 10px;
  gap: 4px;
  border-radius: 4px 4px 0 0;
  color: ${styles.colors.primary600};
  background-color: ${styles.colors.primary100};
  & span {
    color: ${styles.colors.primary600};
  }
  & svg {
    path {
      fill: ${styles.colors.primary600};
    }
  }
`;

const KanbanTableRow = styled(TableRow)`
  background-color: white;
  &:first-child {
    border-radius: 0px;
  }
  &:last-child {
    border-radius: 0px;
  }
`;

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: left;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px 0px;
  height: calc(100vh - 40px);
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 0;
  gap: 16px;
`;

const Columns = styled.div`
  display: flex;
  height: 100%;
  gap: 16px;
`;

const AddColumnButton = styled.button`
  background-color: white;
  width: 325px;
  min-width: 325px;
  height: 100%;
  min-height: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export default KanbanBoard;
