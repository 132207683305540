import { useState } from "react";
import styled from "styled-components";
import { Connection, Id } from "./types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Column from "../../../../components/Column";
import Row from "../../../../components/Row";
import ProfilePhoto from "../../../../components/ProfilePhoto";
import Text from "../../../../components/Text";
import { styles } from "../../../../styles/themes/style";
import ConnectionTags from "../../../../fragments/ConnectionTags";
import SvgFragment from "../../../../fragments/SvgFragment";

interface Props {
  connection: Connection;
  removeConnection: (id: Id) => void;
  connectionClicked?: (connection: Connection) => void;
}

const ConnectionCard = ({
  connection,
  connectionClicked,
  removeConnection,
}: Props) => {
  const [mouseIsOver, setMouseIsOver] = useState(false);

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: connection.id,
    data: { type: "Connection", connection },
  });

  return (
    <CardWrapper
      ref={setNodeRef}
      style={{ transition, transform: CSS.Transform.toString(transform) }}
      isDragging={isDragging}
      onClick={(e: any) => connectionClicked && connectionClicked(connection)}
      onMouseEnter={() => setMouseIsOver(true)}
      onMouseLeave={() => setMouseIsOver(false)}
      {...attributes}
      {...listeners}
    >
      <InfoBoxColumn gap="12px">
        <WrappingRow>
          <ConnectionTags
            itemsDirectionRow={true}
            tags={connection.connection?.tags
              ?.filter((tag) => !tag.isForbidden)
              ?.sort((a, b) => a?.title?.localeCompare(b?.title))}
          />
          {mouseIsOver && (
            <Row
              alignItems
              gap="5px"
              onClick={(e: any) => { e.stopPropagation(); removeConnection(connection.id)}}
            >
              <SvgFragment type="close"/>
            </Row>
          )}
        </WrappingRow>
        <Row gap="8px">
          <ProfilePhotoColumn>
            <ProfilePhoto
              source={connection.connection?.profilePicture}
              capitalLetters={
                !connection.connection?.profilePicture
                  ? `${connection.connection?.firstName
                      ?.charAt(0)
                      .toUpperCase()}${connection.connection?.lastName
                      ?.charAt(0)
                      .toUpperCase()}`
                  : undefined
              }
            />
          </ProfilePhotoColumn>
          <Column>
            <Text $bold>
              {connection.connection?.firstName +
                " " +
                connection.connection?.lastName}
            </Text>
            <Row>
              <Text $ellipsis="1" showTooltip>
                {connection.connection?.headline}
              </Text>
            </Row>
          </Column>
        </Row>
        <Row gap="8px">
          <Column flex={"1"}>
            <Row>
              <Text>Company</Text>
            </Row>
            <Row>
              <Text $ellipsis="1" $faded showTooltip>
                {connection.connection?.p?.find((x) => x.k === "company")?.v}
              </Text>
            </Row>
          </Column>
          <Column flex={"1"}>
            <Row>
              <Text>Location</Text>
            </Row>
            <Row>
              <Text $ellipsis="1" $faded showTooltip>
                {connection.connection?.p?.find((x) => x.k === "location")?.v}
              </Text>
            </Row>
          </Column>
        </Row>
      </InfoBoxColumn>
    </CardWrapper>
  );
};

const WrappingRow = styled(Row)`
  display: flex;
  min-height: 24px;
  justify-content: space-between;
  & svg { cursor: pointer; }
`;

const TaskText = styled.p`
  height: 90%;
  width: 100%;
  overflow-y: auto;
  white-space: pre-wrap;
`;

const TaskInput = styled.textarea`
  height: 90%;
  width: 100%;
  resize: none;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  outline: none;
`;

const DeleteButton = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  padding: 8px;
  border-radius: 8px;

  & svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
  }
`;

const CardWrapper = styled.div<{ isDragging: boolean }>`
  background: transparent;
  color: white;
  padding: 12px;
  height: wrap-content;
  min-height: 218px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: grab;
  position: relative;
  border: 1px solid ${styles.colors.primary600};
  ${({ isDragging }) =>
    isDragging && `opacity: 0.3; border: 2px solid ${styles.colors.primary600}`}

  &:hover {
    border: 2px solid ${styles.colors.primary600};
  }
`;

const InfoBoxColumn = styled(Column)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 8px;
`;
const ProfilePhotoColumn = styled(Column)`
  max-height: 32px;
`;

export default ConnectionCard;
