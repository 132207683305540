import { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ConnectionTable from '../components/connections/ConnectionTable';
import Column from '../../../components/Column';
import ConnectionControlBar from '../components/connections/ConnectionControlBar';
import { IConnection } from '../../../interfaces/models/connection';
import KanbanBoard from './kanban/KanbanBoard';

interface IConnectionSectionProps { }

const ConnectionSection: FunctionComponent<IConnectionSectionProps> = () => {

    const [selectedConnections, setSelectedConnections] = useState<IConnection[]>([]);
    const [selectedAllConnections, setSelectedAllConnections] = useState<boolean>(false);
    const [controlBarHeight, setControlBarHeight] = useState<number>(0);
    const controlBarRef = useRef<HTMLDivElement>(null);
    const [kanbanView, setKanbanView] = useState(false);

    const updateControlBarHeight = () => {
        if (controlBarRef.current) {
            const height = controlBarRef.current.offsetHeight - 2;
            setControlBarHeight(height);
        }
    };
    useEffect(() => {
        updateControlBarHeight();
    }, [controlBarRef.current]);

    useEffect(() => {
        window.addEventListener('resize', updateControlBarHeight);
        return () => {
            window.removeEventListener('resize', updateControlBarHeight);
        };
    }, []);

    const handleDataChange = (selectedConnections: IConnection[]) => {
        setSelectedConnections(selectedConnections);
    };

    const handleAllSelectedChange = (isAllSelected: boolean) => {
        setSelectedAllConnections(isAllSelected);
    };

    const toggleKanbanHandler = () => {
        const isKanbanView = kanbanView;
        setKanbanView(!isKanbanView);
    }

    return <Container>
        {!kanbanView && 
            <Column>
                <StickyControlBar ref={controlBarRef}>
                    <ConnectionControlBar selectedConnections={selectedConnections} selectedAllConnections={selectedAllConnections} toggleKanban={toggleKanbanHandler} showKanban={!kanbanView} />
                </StickyControlBar>
                <ConnectionTable controlBarHeight={controlBarHeight} selectedConnectionsChange={handleDataChange} selectedConnections={selectedConnections} selectedAllConnectionsChange={handleAllSelectedChange} />
            </Column>
        }
        {kanbanView && 
            <HeightColumn>
                <StickyControlBar ref={controlBarRef}>
                    <ConnectionControlBar selectedConnections={selectedConnections} selectedAllConnections={selectedAllConnections} toggleKanban={toggleKanbanHandler} showKanban={!kanbanView} />
                </StickyControlBar>
                <KanbanBoard selectedConnections={selectedConnections} />
            </HeightColumn>
        }
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
`

const StickyControlBar = styled.div`
    position: sticky;
    top: 0;
    z-index: 101;
    background: ${({theme: { tertiaryColor }}) => tertiaryColor};
    padding-top: 16px;
`;

const HeightColumn = styled(Column)`
    height: -webkit-fill-available;
`

export default ConnectionSection
