import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import { useConnectionsStore } from '../../../../stores/connections/useConnectionsStore'
import Row from '../../../../components/Row'
import { useConnectionsHandlers } from '../../../../services/actions/connections/useConnectionsHandlers'
import FilterButton from '../../../../fragments/FilterButton'
import { AppRoutes, SidebarTypes } from '../../../../interfaces/store/appStore'
import Button from '../../../../components/Button'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { useNavigate } from 'react-router-dom'
import ConnectionSearchFilter from '../../../../fragments/ConnectionSearchFilter'
import { useSaveFilterHandlers } from '../../../../services/actions/save-filters/useSaveFilterHandlers'
import ConnectionCustomizeColumns from '../../../../fragments/ConnectionCustomizeColumns'
import { IConnection } from '../../../../interfaces/models/connection'
import BulkConnectionActionsDropdown from '../../../../fragments/dropdowns/BulkConnectionActionsDropdown'
import Column from '../../../../components/Column'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import ConnectionFilterLabels from '../../../../fragments/ConnectionFilterLabels'
import { useAppStore } from '../../../../stores/application/useAppStore'
import { styles } from '../../../../styles/themes/style'
import { useAnalyticsHandlers } from '../../../../services/actions/analytics/useAnalyticsHandlers'
import { isLNFirst } from './../../../../utils';

interface IConnectionControlBarProps { 
    isMobile?: any,
    isSmall?: any,
    selectedConnections: IConnection[],
    selectedAllConnections?: boolean,
    showKanban?: boolean,
    toggleKanban: () => void;
}

interface IRowStyle {
    showMobile?: boolean
}

interface IAnalytics {
    "connections-updated-connected-to-linkedin": number
}

const ConnectionControlBar: FunctionComponent<IConnectionControlBarProps> = ({selectedConnections, selectedAllConnections, showKanban, toggleKanban}) => {
    const navigate = useNavigate()
    const hasCalledForMultipleConditions = useRef(false);
    const { store: { user, isLoggedToLinkedin, trial, disconnectedConnectionsCount, workspace } } = useAuthStore()
    const { store: { extensionInstalled, extensionVersion, requestLoading } } = useAppStore()
    const { store: { connectionsFilter, connectionsParameters }} = useConnectionsStore()
    const { page, pageSize, total } = connectionsParameters
   
    const { setConnectionsFilterHandler, setConnectionsParametersHandler, sendMessageToConnectionsHandler, exportToCSVHAndler, numberOfConnectionsFiltersHandler, setClearConnectionsFilterHandler, getConnectionsHandler, getMultipleConditionsFiltersHandler, toggleBulkHideConnectionsHandler, scheduleConnectionsRemovalHandler, getSelectedConnections, bulkAddConnectionsToKanbanHandler } = useConnectionsHandlers()
    const { openSidebar, closeSidebar, setExportConnectionsModalHandler, setScheduleDisconnectModalHandler, setConfirmationModal, hideModal, showErrorToast } = useAppHandlers()
    const { getSavedFiltersHandler } = useSaveFilterHandlers()

    const [savedFilters, setSavedFilters] = useState<any>([])
    const [hasLinkedInFirstConnection, setHasLinkedInFirstConnection] = useState<boolean>(false)

    const [selectedConnectionsLoading, setSelectedConnectionsLoading] = useState<boolean>(false)

    useEffect(() => {
        if('getSelectedConnectionsIds' in requestLoading){
            setSelectedConnectionsLoading(requestLoading?.['getSelectedConnectionsIds'])
        }
    }, [requestLoading]);

    const { getTotalConnectionsOverviewAnalytics } = useAnalyticsHandlers();

    const [ analytics, setAnalytics ] = useState<IAnalytics>({
        "connections-updated-connected-to-linkedin": 0,
    })

    const isExtensionVersionCorrect = extensionVersion === process.env.REACT_APP_VERSION

    useEffect(() => {
        getConnectionsHandler();
        if (!hasCalledForMultipleConditions.current) {
            getMultipleConditionsFiltersHandler();
            hasCalledForMultipleConditions.current = true;
        }
    }, [connectionsFilter, page, pageSize ]);

    useEffect(() => {
        getSavedFilters()
    }, [connectionsFilter.savedFilter])

    const getSavedFilters = async () => {
        const result = await getSavedFiltersHandler()
        setSavedFilters(result ?? [])
    }

    const exportConnections = useCallback(async (includeTags: boolean, includeNotes: boolean, exportAll: boolean) => {
        if(exportAll){
            await exportToCSVHAndler(includeTags, includeNotes)
        }
        else {
            await exportToCSVHAndler(includeTags, includeNotes, selectedConnections)
        }
    }, [selectedConnections, connectionsFilter]) 

    const searchHandler = useCallback((e: any) => {
        console.log(e);
        
        const updatedFilter = {
            ...connectionsFilter,
            searchValue: e,
            searchByName: 'yes', // TODO
            sortClicked: false
        };
        
        setConnectionsFilterHandler(updatedFilter);
    }, [connectionsFilter, setConnectionsFilterHandler]); 


    const isCsvConnection = (connection: IConnection) => {
        const hasCSVOrImportedTag = connection.tags?.some(item => item.title === 'CSV' || item.title === 'Imported');
        const hasLinkedinTag = connection.tags?.some(item => item.title === 'LinkedIn' || item.title === 'LinkedIn 1st' || item.title === 'Disconnected');
    
        return hasCSVOrImportedTag && !hasLinkedinTag;
    };

    const resetFilterHandler = useCallback(() => {
        setClearConnectionsFilterHandler();
    }, [connectionsFilter, setConnectionsFilterHandler]);

    useEffect(() => {
        for (let selectedConnection of selectedConnections) {
            if (isLNFirst(selectedConnection, user?._id)) {
                setHasLinkedInFirstConnection(true)
                break
            } else {
                setHasLinkedInFirstConnection(false)
            }
        }
    }, [selectedConnections])
    
    let user1stDegreeSelectedConnections = selectedConnections?.filter(
        (selectedConnection) =>
            isLNFirst(selectedConnection, user?._id) && // use only Ln-1st connections
            !selectedConnection?.autoTagsArray?.some((tagArrayItem: any) => tagArrayItem?._id === user?._id && // skip disconnected connections
                    tagArrayItem?.autoTags?.some(
                        (tag: any) => tag?.title === "Disconnected"
                    )
            )
    );

    const scheduleDisconnectHandler = useCallback(async () => {
        if(user1stDegreeSelectedConnections?.length){
          scheduleConnectionsRemovalHandler(user1stDegreeSelectedConnections, !!selectedAllConnections);
        }
    }, [selectedConnections, selectedAllConnections, user1stDegreeSelectedConnections])

    const clickOnRemoveHandler = async () => {
        let totalConnections = user1stDegreeSelectedConnections?.length;

        if (selectedAllConnections) {
            // get selected connections IDs
            user1stDegreeSelectedConnections = await getSelectedConnections(total);
            totalConnections = user1stDegreeSelectedConnections?.length;
        }

        if(trial && (disconnectedConnectionsCount + user1stDegreeSelectedConnections?.length > 25)){
            setConfirmationModal({
                headingText: `${disconnectedConnectionsCount >= 25 ? 'Free Trial Limit Reached' : 'You Hit the Free Trial Limit'}`,
                descriptionText: `${disconnectedConnectionsCount >= 25 
                    ? 'You’ve reached your free trial limit of 25 removed connections. Upgrade to LeadDelta Pro or Starter to schedule more removals and take full control of your professional network.' 
                    : 'Free trial users can remove up to 25 connections. Upgrade to LeadDelta Pro or Starter to schedule more removals and take full control of your professional network.'}`,
                onCancelHandler: () => hideModal(),
                onConfirmHandler: async () => {
                    hideModal();
                    window.open(AppRoutes.BILLING, '_blank');
                },
                confirmBtnTitle: 'Upgrade Plan'
            });

            disconnectedConnectionsCount >= 25 ? showErrorToast({message: 'Free trial limit reached.'}) : showErrorToast({message: 'You hit the free trial limit.'});
        } else {
            setScheduleDisconnectModalHandler(
                scheduleDisconnectHandler,
                selectedAllConnections,
                totalConnections
            )
        }
    }

    const getTotalConnectionsOverviewAnalyticsHandler = useCallback(async () => {
        const statistics = await getTotalConnectionsOverviewAnalytics();
        let statisticsObject = statistics.reduce(
          (accumulator, value) => ({
            ...accumulator,
            ...{ [value.version]: value.value },
          }),
          {}
        );
        setAnalytics({ ...analytics, ...statisticsObject });
        return statisticsObject;
      }, []);

    return <Column>
            <Row gap='8px' alignItems spaceBetween flexWrap>
            {showKanban && 
                <ShowMobile>
                    <StyledRow gap="12px" showMobile>
                        <ConnectionSearchFilter searchValue={connectionsFilter?.searchValues[0]?.searchValue} />
                    </StyledRow>
                </ShowMobile>
            }
            <StyledRow>
                <Row gap="8px">
                    <Button 
                        $SVGtype={showKanban ? 'kanban' : 'list'}
                        $type="white"
                        $color={styles.colors.primary600}
                        $iconColor={styles.colors.black300}
                        onClickHandler={toggleKanban}>{showKanban ? 'Kanban' : 'List'}</Button>
                    {showKanban && 
                    <>
                        <ConnectionCustomizeColumns />
                        <Button 
                            $type="white" 
                            disabled={ workspace?.isStarter || user1stDegreeSelectedConnections?.length === 0 || selectedAllConnections || user1stDegreeSelectedConnections?.length > 25 || !extensionInstalled || !isLoggedToLinkedin || !isExtensionVersionCorrect } 
                            $customWidth
                            $SVGtype="message"
                            $color={styles.colors.primary600}
                            $iconColor={styles.colors.black300}
                            onClickHandler={() => 
                                openSidebar(SidebarTypes.BULK_MESSAGE, { 
                                    formType: 'message', 
                                    onMessageSaveHandler: (message: string) => 
                                        sendMessageToConnectionsHandler(user1stDegreeSelectedConnections, message)
                                })
                            }
                            tooltipMessage={ 
                                !extensionInstalled ? 'Install Extension' 
                                : !isExtensionVersionCorrect ? 'Update extension' 
                                : !isLoggedToLinkedin ? 'Login to Linkedin'
                                : workspace?.isStarter ? 'Upgrade to Pro to unlock this feature'
                                : '' }
                        >
                            {'Message'}
                        </Button>
                        <Button
                            $type="white"
                            disabled={
                                (user1stDegreeSelectedConnections?.length === 0 && !selectedAllConnections)
                                || !(isLoggedToLinkedin && extensionInstalled && isExtensionVersionCorrect)
                            }
                            $customWidth
                            $color={styles.colors.red600}
                            $iconColor={styles.colors.black300}
                            $SVGtype="profileRemove"
                            tooltipMessage={
                                user1stDegreeSelectedConnections?.length === 0 
                                ? 'Select connections to schedule for removal'
                                : !(extensionInstalled && isExtensionVersionCorrect) 
                                ? 'Install or update the LeadDelta extension to use this feature'
                                : !isLoggedToLinkedin 
                                ? 'Log in to your LinkedIn account to use this feature'
                                : 'Select connections to schedule for removal'
                            }
                            onClickHandler={clickOnRemoveHandler}
                            isLoading={selectedConnectionsLoading}
                        >
                            {'Remove'}
                        </Button>
                        <Button 
                            $type="white" 
                            disabled={selectedConnections?.length === 0} 
                            $customWidth
                            $SVGtype="label"
                            $color={styles.colors.primary600}
                            $iconColor={styles.colors.black300}
                            tooltipMessage="Tag connections"
                            onClickHandler={() =>
                                openSidebar(SidebarTypes.APPLY_TAG, {
                                    selectedAllConnections: selectedAllConnections,
                                    selectedConnectionsIds: selectedConnections?.map(connection => connection._id),
                                    onSaveHandler: () => closeSidebar(SidebarTypes.APPLY_TAG)
                                })
                            }
                        >
                            {'Tag'}
                        </Button>
                        <ResponsiveContainerSmall>
                            <Button
                                $type="white"
                                disabled={selectedConnections?.length === 0}
                                $customWidth
                                $SVGtype="crossedLabel"
                                $color={styles.colors.primary600}
                                $iconColor={styles.colors.black300}
                                tooltipMessage="Untag connections"
                                onClickHandler={() => 
                                    openSidebar(SidebarTypes.REMOVE_TAG, { 
                                        selectedAllConnections: selectedAllConnections, 
                                        selectedConnections: selectedConnections,
                                        onSaveHandler: () => closeSidebar(SidebarTypes.REMOVE_TAG) 
                                    })
                                }
                            >
                                {'Untag'}
                            </Button>
                            <Button
                                $type="white"
                                disabled={selectedConnections?.length === 0}
                                $customWidth
                                $SVGtype="hide"
                                $color={styles.colors.primary600}
                                $iconColor={styles.colors.black300}
                                tooltipMessage="Hide connections"
                                onClickHandler={() => toggleBulkHideConnectionsHandler(selectedConnections, true)}
                            >
                                {'Hide'}
                            </Button>
                        </ResponsiveContainerSmall>
                        <ActionDropdownContainer>
                            <BulkConnectionActionsDropdown selectedConnections={selectedConnections} selectedAllConnections={selectedAllConnections} />
                        </ActionDropdownContainer>
                        <ResponsiveContainerMeduim>
                            <Button
                                $type="white"
                                disabled={selectedConnections?.length === 0}
                                $customWidth
                                $SVGtype="show"
                                $color={styles.colors.primary600}
                                $iconColor={styles.colors.black300}
                                tooltipMessage="Unhide connections"
                                onClickHandler={() => toggleBulkHideConnectionsHandler(selectedConnections, false)}
                            >
                                {'Unhide'}
                            </Button>
                            <Button
                                $type="white"
                                disabled={selectedConnections?.length === 0}
                                $bigButton
                                $SVGtype="kanban"
                                $color={styles.colors.primary600}
                                $iconColor={styles.colors.black300}
                                tooltipMessage="Add connections to kanban"
                                onClickHandler={() => bulkAddConnectionsToKanbanHandler(selectedConnections)}
                            >
                                {'Add to Kanban'}
                            </Button>
                            <Divider />
                            <StyledRow gap="10px">
                                <Button 
                                    $type="white" 
                                    $smallButton
                                    $SVGtype="import"
                                    $color={styles.colors.primary600}
                                    $iconColor={styles.colors.black300}
                                    disabled={workspace?.isStarter}
                                    tooltipMessage={workspace?.isStarter ? 'Upgrade to unlock this feature' : 'Import CSV file'}
                                    onClickHandler={() => navigate(AppRoutes.CSV_IMPORT)}
                                />
                                <Button 
                                    $type="white" 
                                    disabled={total === 0 || workspace?.isStarter} 
                                    $smallButton
                                    $SVGtype="export"
                                    $iconColor={styles.colors.black300}
                                    tooltipMessage={workspace?.isStarter ? 'Upgrade to unlock this feature' : 'Export connections'}
                                    onClickHandler={() => {
                                            setExportConnectionsModalHandler(
                                                exportConnections,
                                                selectedAllConnections ? total : selectedConnections?.length ?? undefined,
                                                selectedAllConnections
                                            )
                                        }
                                    }
                                />
                            </StyledRow>
                        </ResponsiveContainerMeduim>
                    </>
                    }
                </Row>
            </StyledRow>
            {showKanban && 
                <Row gap="8px" alignItems>
                    <Row>
                        <TablePageNavigation
                            page={page ?? 1}
                            pageSize={pageSize ?? 50}
                            totalCount={total ?? 0}
                            onPageChanged={(value: number) => setConnectionsParametersHandler({...connectionsParameters, page: value})}
                            onPageSizeChanged={(value: number) => setConnectionsParametersHandler({...connectionsParameters, pageSize: value})}
                        />
                    </Row>
                    <StyledRow>
                        <ConnectionSearchFilter searchValue={connectionsFilter?.searchValues[0]?.searchValue} />
                    </StyledRow>
                    <Divider />
                    <Row>
                        <FilterButton
                            sidebar={SidebarTypes.CONNECTION_FILTER}
                            savedFilters={savedFilters}
                            numberOfFilters={numberOfConnectionsFiltersHandler()}
                            onSetSavedFilter={(savedFilter: any) => setConnectionsFilterHandler({...connectionsFilter, ...savedFilter.filters})}
                            resetFilter={() => resetFilterHandler()}
                        />
                    </Row>
                    <ShowMobile>
                        <BulkConnectionActionsDropdown 
                            selectedConnections={selectedConnections} 
                            selectedAllConnections={selectedAllConnections} />
                    </ShowMobile>
                </Row>
            }
            </Row>
            {showKanban && 
                <Row>
                    <ConnectionFilterLabels
                        searchValues={connectionsFilter?.searchValues}
                        connectionsFilter={connectionsFilter}
                        resetFiltersHandler={resetFilterHandler}
                        saveFiltersHandler={() => openSidebar(SidebarTypes.SAVE_FILTER)} />
                </Row>
            }
    </Column>
}

const Divider = styled.div`
    height: 18px;
    width: 2px;
    background: ${({ theme: { quinaryColor } }) => `${quinaryColor}`};

    @media (max-width: 575px) {
        display: none;
    }
`
const StyledRow = styled(Row)<IRowStyle>`
    ${({showMobile}) => !showMobile && `
        @media (max-width: 575px) {
            display: none;
        }
    `}
`
const ShowMobile = styled(Row)<IRowStyle>`
    display: none;

    @media (max-width: 575px) {
        width: 100%;
        display: block;
    }
`
const ResponsiveContainerMeduim = styled(Row)<IRowStyle>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    @media (max-width: 1738px) {
        display: none;
    }
`
const ActionDropdownContainer = styled(Row)<IRowStyle>`
    display: none;

    @media (max-width: 1738px) {
        display: block;
    }
`
const ResponsiveContainerSmall = styled(Row)<IRowStyle>`
    display: flex;
    flex-direction: row;
    gap: 8px;

    @media (max-width: 1414px) {
        display: none;
    }
`

export default ConnectionControlBar