import { FunctionComponent, useEffect, useState } from "react"
import { DefaultTheme, useTheme } from "styled-components"
import { styles } from "../../styles/themes/style";
import Async from "react-select/async";
import { Props, StylesConfig } from "react-select";

 interface IStyledSelectProps extends Props {
    maxLength?: number,
    isMulti?: boolean,
    showOnFocus?: boolean,
    width?: string,
    onInputChangeHandler?: (e: string) => void,
    onChangeHandler?: (newValue: any, actionMeta: any) => void,
    loadOptions?: (inputValue: string, callback: (options: any) => void) => void
  }

 /**
  * Use Select as the form control, search select, mutli select etc.
  */
const AsyncSelect: FunctionComponent<IStyledSelectProps> = ({ components, maxLength, showOnFocus, width, onInputChangeHandler, onChangeHandler, isMulti, ...props }) => {

    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false); // Dodato za praćenje fokusa

    const onInputChangeInternal = (event: string) => {
        !maxLength || event.length <= maxLength ? setInputValue(event) : setInputValue(inputValue);
    }

    const handleChange = (selectedOption: any, actionMeta: any) => {
        setIsFocused(false);
        
        onChangeHandler && onChangeHandler(selectedOption, actionMeta);
    };
    
    useEffect(() => {
        onInputChangeHandler && onInputChangeHandler(inputValue) 
    }, [inputValue])
    const theme: DefaultTheme = useTheme();
    let customStyles: StylesConfig = {
        control: (base, props) => {
            return {
                 ...base,
                display: 'flex',
                alignItems: 'center',
                height: isMulti ? 'auto' : 40,
                minHeight: 34,
                boxSizing: 'border-box',
                padding: '0',
                border: `2px solid ${ theme.quinaryColor}`,
                cursor: props.selectProps.isDisabled ? 'default' : 'pointer',
                boxShadow: 'none',

                '& div p': {
                    margin: '0',
                    height: '24px'
                },
                '&:hover': {
                    boxShadow: 'none',
                    border: `2px solid ${props.selectProps.isDisabled ? styles.colors.black200 : styles.colors.primary600}`
                },
                '&:active': {
                    boxShadow: 'none',
                    border: `2px solid ${props.selectProps.isDisabled ? styles.colors.black200 : styles.colors.primary600}`
                }
            }
        },
        container: () => ({
            ...(theme.primary ? {} : {border: `2px solid ${styles.colors.darkblack500}`} ),

        }),
        menu: (base, props) => {
            return {
                backgroundColor: theme.secondaryColor,
                borderRadius: '3px',
                marginTop: '5px',
                padding: 0,
                boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.1)',
                position: 'absolute',
                bottom: props.selectProps.menuPlacement === 'top' ? '90px' : 'unset',
                width: width ? width : '100%',
                zIndex: 1004,
                cursor: props.selectProps.isDisabled ? 'none' : 'pointer',
                '& > div > div': {
                    width: '100%', 
                    margin: '0px'
                },
                
                '&::after': {
                    content: '"Type to refine results or to create a new"',
                    color: theme.primaryColor,
                    fontSize: '12px',
                    fontFamily: 'NunitoSans',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '5px 20px 0',
                    lineHeight: '16px'
                }
            }
        },
        menuList: (base, props) => {
            return {
                ...base,
                maxHeight: 400
            }
        },
        dropdownIndicator: (base, props) => {
            return {
                ...base,
                padding: '0px',
                marginRight: '6px',
                color: styles.colors.black300,
                transition: 'all .2s ease',
                transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,

                '&:hover': {
                    color: props.selectProps.isDisabled ? styles.colors.black300 : styles.colors.primary600
                }
            }
        },
        valueContainer: (base, props) => {
            return {
                height: '30px',
                padding: '0 6px',
                display: 'flex',
                alignItems: 'center'
            }
        },
        indicatorsContainer: (base, props) => {
            return {
                ...base,
                alignSelf: 'center',
                height: '20px',
                width: '20px',
                padding: '0px',
                marginRight: '6px'
            }
        },
        option: (base, props) => {
            return {
                textAlign: 'left',
                fontFamily: 'NunitoSans',
                fontSize: '14px',
                fontWeight: '400',
                color: theme.primaryColor,
                padding: '6px 20px',
                cursor: 'pointer',
    
                '&:hover': {
                    color: styles.colors.primary600,
                    backgroundColor: theme.senaryColor,
                }
            }
        },
        placeholder: () => ({
            lineHeight: '30px',
            fontFamily: 'NunitoSans',
            fontSize: '14px',
            color: styles.colors.black300
        }),
        singleValue: (base, props) => {
            return {
                ...base,
                color: props.selectProps.isDisabled ? styles.colors.black300 : theme.primaryColor,
                alignItems: 'center',
                fontFamily: 'NunitoSans',
                fontSize: '14px',
                lineHeight: '30px',
                backgroundColor: 'transparent',
            }
        }
    }

    if(isMulti) {
        customStyles = {
            ...customStyles,
            valueContainer: (base, props) => {
                return {
                    ...base,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    height: '100%', 
                    padding: '8px 10px'
                }
            }
        }
    }
    
    return (
        <Async
            styles={customStyles}
            cacheOptions={true}
            isMulti={isMulti}
            onInputChange={(newValue) => onInputChangeInternal(newValue)}
            inputValue={inputValue}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            menuIsOpen={showOnFocus && isFocused}
            components={{
                IndicatorSeparator: () => null,
                ...components
            }}
            onChange={handleChange}
            { ...props }
        />
    )
}

export default AsyncSelect