import { FunctionComponent, useState } from "react";
import Row from "./Row";
import DropdownMenu from "./dropdown/DropdownMenu";
import DropdownItem from "./dropdown/DropdownItem";
import Text from "./Text";
import styled from "styled-components";
import Column from "./Column";
import { IKanban } from "../interfaces/models/kanban";
import { styles } from "../styles/themes/style";
import { Id } from "../pages/canvas/sections/kanban/types";
import moment from "moment";

interface IKanbanInfoPopupProps {
  kanban?: IKanban;
  updateKanban?: (id: Id, value: string, isName: boolean) => void;
  removeKanban?: () => void;
}

const KanbanInfoPopup: FunctionComponent<IKanbanInfoPopupProps> = ({
  kanban,
  updateKanban,
  removeKanban,
}) => {
  const [editModeName, setEditModeName] = useState(false);
  const [editModeDesc, setEditModeDesc] = useState(false);

  return (
    <StyledContainer>
      <DropdownMenu
        $unsetOverflow
        $transparent
        title={kanban ? kanban.name : `Kanban title`}
        $color={styles.colors.black600}
        $fontSize="18px"
        autoClose="outside"
      >
        <StyledRow>
          <StyledColumn>
            <Column gap="4px">
              <DropdownItem>
                <NameWrapper onClick={() => kanban && setEditModeName(true)}>
                  {!editModeName ? (
                    <TextTitle>
                      {kanban ? kanban.name : `Kanban title`}
                    </TextTitle>
                  ) : (
                    <input
                      autoFocus
                      style={{
                        backgroundColor: "white",
                        width: "360px",
                        border: `1px solid ${styles.colors.primary600}`,
                        borderRadius: "4px",
                        padding: "2px 0px",
                        outline: "none",
                      }}
                      defaultValue={kanban?.name}
                      onBlur={() => setEditModeName(false)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const target = e.target as HTMLInputElement;
                          updateKanban &&
                            updateKanban(kanban?._id as Id, target.value, true);
                          setEditModeName(false);
                        }
                      }}
                    />
                  )}
                </NameWrapper>
              </DropdownItem>
              <DropdownItem>
                <NameWrapper onClick={() => kanban && setEditModeDesc(true)}>
                  {!editModeDesc ? (
                    <TextDescription>
                      {kanban ? kanban.description : `Kanban description`}
                    </TextDescription>
                  ) : (
                    <input
                      autoFocus
                      style={{
                        backgroundColor: "white",
                        width: "360px",
                        border: `1px solid ${styles.colors.primary600}`,
                        borderRadius: "4px",
                        padding: "2px 0px",
                        outline: "none",
                      }}
                      defaultValue={kanban?.description}
                      onBlur={() => setEditModeDesc(false)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const target = e.target as HTMLInputElement;
                          updateKanban &&
                            updateKanban(
                              kanban?._id as Id,
                              target.value,
                              false
                            );
                          setEditModeDesc(false);
                        }
                      }}
                    />
                  )}
                </NameWrapper>
              </DropdownItem>
            </Column>
            <DropdownItem $nonHoverable>
              <Text $heading5>{`Kanban info`}</Text>
            </DropdownItem>
            {kanban && (
              <DropdownItem $nonHoverable>
                <Row gap="4px">
                  <TextInfo>{`Owner:`}</TextInfo>
                  <Text $placeholder>{`${kanban?.user?.firstName ?? ""} ${
                    kanban?.user?.lastName ?? ""
                  }`}</Text>
                </Row>
              </DropdownItem>
            )}
            {kanban && (
              <DropdownItem $nonHoverable>
                <TextInfo>{`Date created: ${
                  kanban?.createdAt
                    ? moment(kanban?.createdAt).format("MMM D, YYYY, hh:mm")
                    : ""
                }`}</TextInfo>
              </DropdownItem>
            )}
            <DropdownItem $nonHoverable>
              <Divider />
            </DropdownItem>
            <DropdownItem onClickHandler={removeKanban}>
              <Text $red $heading5>{`Delete`}</Text>
            </DropdownItem>
          </StyledColumn>
        </StyledRow>
      </DropdownMenu>
    </StyledContainer>
  );
};

const StyledContainer = styled(Row)`
  min-width: 420px;
  gap: 10px;
  align-items: center;

  & .actions-dropdown + div {
    min-width: 420px;
    padding: 24px 16px 16px 16px;
  }
`;
const StyledRow = styled(Row)`
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledColumn = styled(Column)`
  width: 100%;
`;
const TextTitle = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 24x;
`;

const TextDescription = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24x;
`;

const TextInfo = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24x;
  color: ${styles.colors.black300};
`;

const NameWrapper = styled.div`
  cursor: pointer;
`;
const Divider = styled.div`
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid ${styles.colors.black200};
`;

export default KanbanInfoPopup;
