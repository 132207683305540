import { FunctionComponent } from 'react'
import Tag from '../components/Tag'
import { styles } from '../styles/themes/style'
import { ITag } from '../interfaces/models/connection'

interface IAppTagProps {
    tag: ITag
    $width?: string
    onDestructiveClickHandler?: (e: any) => void
 }

const AppTag: FunctionComponent<IAppTagProps> = ({ tag, $width, onDestructiveClickHandler }) => {

    let SVGtype

    if (['LinkedIn', 'LinkedIn 1st', 'Imported'].includes(tag.title)) SVGtype = "linkedin"  
    //else if (['Imported'].includes(tag.title)) SVGtype = "imported" 
    else if (['New Connection'].includes(tag.title)) SVGtype = "sync" 
    else if (['CSV'].includes(tag.title)) SVGtype = "csv"
    else if (['Disconnected'].includes(tag.title)) SVGtype = "disconnectedWhite"
    else if (['Prospect'].includes(tag.title)) SVGtype = "leadDelta"
    else if (['Removal Scheduled'].includes(tag.title)) SVGtype = "clockWhite"
    else if (tag.forKanban) SVGtype = "kanbanWhite"
    else if (!tag.private) SVGtype = "tagCompany"
    else SVGtype = ""
    return  <Tag 
        onDestructiveClickHandler={onDestructiveClickHandler}
        $backgroundColor={SVGtype.length > 0 && tag.isForbidden && SVGtype !== 'disconnectedWhite' && SVGtype !== 'clockWhite' ? '' : tag.color}
        $borderColor={SVGtype.length > 0 && tag.isForbidden ? ((SVGtype === 'disconnectedWhite' || SVGtype === 'clockWhite') ? tag.color : styles.colors.primary600) : styles.colors.white} 
        $color={SVGtype.length > 0 && tag.isForbidden ? '' : tag.color} 
        title={tag.title === 'Disconnected' ? (tag?.removedFromLD === false ? 'Li Removed' : 'LD Removed') : tag.title} 
        SVGtype={SVGtype}
        $width={$width}
        $isDisconnected={SVGtype==='disconnectedWhite' || SVGtype === 'clockWhite'}    
        />
}

export default AppTag
