import { FunctionComponent } from "react";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import SvgFragment from "../SvgFragment";
import DropdownItem from "../../components/dropdown/DropdownItem";
import Row from "../../components/Row";
import { Column } from "../../pages/canvas/sections/kanban/types";
import { styles } from "../../styles/themes/style";

interface IKanbanColumnActionDropdownProps {
  column: Column;
  className?: string;
  onChangeColorHandler: (e: any) => void;
  onDeleteHandler: (e: any) => void;
}

const KanbanColumnActionDropdown: FunctionComponent<
  IKanbanColumnActionDropdownProps
> = ({ className, column, onChangeColorHandler, onDeleteHandler }) => {
  const editDeleteActions = [
    {
      icon: "pen",
      title: `Change color`,
      slug: "edit",
      enabled: true,
      visible: true,
      onClickHandler: () =>
        onChangeColorHandler && onChangeColorHandler(column),
    },
    {
      icon: "trashCan",
      title: `Delete column`,
      slug: "delete",
      enabled: true,
      destructive: true,
      visible: true,
      onClickHandler: () => onDeleteHandler && onDeleteHandler(column),
    },
  ];
  return (
    <DropdownMenu
      className={className}
      SVGtype="threeDots"
      $hideToggle
      svgHoverChangeColor={false}
      $transparent
      tooltipMessage={"Actions"}
    >
      {editDeleteActions.map((editDeleteAction, index) =>
        editDeleteAction.enabled ? (
          <DropdownItem
            key={index}
            $danger={editDeleteAction.destructive}
            onClickHandler={() =>
              editDeleteAction.onClickHandler &&
              editDeleteAction.onClickHandler()
            }
          >
            <Row alignItems gap="5px">
              <SvgFragment type={editDeleteAction.icon} />
              {editDeleteAction.title}
            </Row>
          </DropdownItem>
        ) : null
      )}
    </DropdownMenu>
  );
};

export default KanbanColumnActionDropdown;
