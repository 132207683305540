import { useMemo, useState } from "react";
import styled from "styled-components";
import { Column, Connection, Id } from "./types";
import { useSortable, SortableContext } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ConnectionCard from "./ConnectionCard";
import { styles } from "../../../../styles/themes/style";
import Text from "../../../../components/Text";
import ConnectionAutocompleteSelect from "../../../../fragments/ConnectionAutocompleteSelect";
import KanbanColumnActionDropdown from "../../../../fragments/dropdowns/KanbanColumnActionDropdown";

interface Props {
  column: Column;
  connections: Connection[];
  updateColumn: (id: Id, title: string) => void;
  addConnectionToColumn: (columnId: Id, event: any) => void;
  removeConnection: (id: Id) => void;
  connectionClicked: (connection: Connection) => void;
  removeColumn: (column: Column) => void;
  changeColumnColor: (column: Column) => void;
}

const ColumnContainer = (props: Props) => {
  const [editMode, setEditMode] = useState(false);
  const {
    column,
    updateColumn,
    addConnectionToColumn,
    connections,
    removeConnection,
    connectionClicked,
    removeColumn,
    changeColumnColor,
  } = props;
  const connectionIds = useMemo(
    () => connections.map((connection) => connection.id),
    [connections]
  );

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: { type: "Column", column },
    disabled: editMode,
  });

  return (
    <ColumnWrapper
      ref={setNodeRef}
      style={{ transition, transform: CSS.Transform.toString(transform) }}
      isDragging={isDragging}
    >
      <ColumnHeader
        {...attributes}
        {...listeners}
        onClick={() => setEditMode(true)}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {!editMode ? (
            <Text $white $bold>
              {column.title}
            </Text>
          ) : (
            <input
              autoFocus
              style={{
                backgroundColor: "white",
                border: `1px solid ${styles.colors.primary600}`,
                borderRadius: "4px",
                padding: "4px",
                outline: "none",
              }}
              defaultValue={column.title}
              // onChange={(e) => updateColumn(column.id, e.target.value)}
              onBlur={() => setEditMode(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const target = e.target as HTMLInputElement;
                  updateColumn(column.id, target.value);
                  setEditMode(false);
                }
              }}
            />
          )}
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
          onClick={(e: any) => e.stopPropagation()}
        >
          <KanbanColumnActionDropdown
            column={column}
            onDeleteHandler={removeColumn}
            onChangeColorHandler={changeColumnColor}
          />
        </div>
      </ColumnHeader>
      <ConnectionAutocompleteSelectWrapper>
        <ConnectionAutocompleteSelect
          showOnFocus={true}
          width={"309px"}
          showValue={false}
          onChangeHandler={(e: any) => addConnectionToColumn(column.id, e)}
        />
      </ConnectionAutocompleteSelectWrapper>
      <ColumnContent>
        <SortableContext items={connectionIds}>
          {connections.map((connection) => (
            <ConnectionCard
              key={connection.id}
              connection={connection}
              removeConnection={removeConnection}
              connectionClicked={connectionClicked}
            />
          ))}
        </SortableContext>
      </ColumnContent>
    </ColumnWrapper>
  );
};

const ColumnWrapper = styled.div<{ isDragging: boolean }>`
  background-color: white;
  width: 325px;
  min-width: 325px;
  height: 100%;
  min-height: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  ${({ isDragging }) =>
    isDragging && `border: 2px solid ${styles.colors.primary600};`}
`;

const ColumnHeader = styled.div`
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0 0;
  padding: 0px 8px;
  color: ${styles.colors.white};
  background-color: ${styles.colors.primary600};
  & svg path {
    fill: ${styles.colors.white};
  }
  &:hover svg path {
    fill: ${styles.colors.black200};
  }
`;

const ColumnContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 0;
  height: 0;
`;
const ConnectionAutocompleteSelectWrapper = styled.div`
  position: relative;
  margin: 8px;
`;

export default ColumnContainer;
